<template>
  <planDefault>
    <template #headerStatistics>
      <statisticsCircle
          class="assessment-plan__expander__statistics-circle"
          :max="assessmentPlan.totalScore"
          v-model="assessmentPlan.score"
      />
    </template>
    <template #default>
      <Alert
        class="mb-4"
        v-if="assessmentPlan.isCompleted"
        type="warning"
      >
        План оценок завершен
      </Alert>
      <testPlanRows>
        <testPlanRow
            v-for="(row, index) in rows"
            :key="index"
            :tests="row.items"
            :title="row.title"
            :loading="isLoading"
            :show_progress="assessmentPlan.show_progress"
        />
      </testPlanRows>
    </template>
  </planDefault>
  <router-view/>
</template>

<script>
import planDefault from '@/components/workspace/assessmentPlans/plans/plan/default/index.vue';
import testPlanRow from '@/components/workspace/assessmentPlans/plans/plan/test/row/index.vue';
import testPlanRows from '@/components/workspace/assessmentPlans/plans/plan/test/rows/index.vue';
import mtExpanderItem from '@/components/UI/mtExpander/mtExpanderItem.vue';
import statisticsCircle from '@/components/workspace/assessmentPlans/plans/statisticsCircle/index.vue';
import {mapGetters, mapState} from 'vuex';
import useToast from "@/components/UI/AppToast/useToast";
import Alert from '@/components/UI/AppAlert/AppAlert.vue';

export default {
  name: 'planRespondent',
  computed: {
    ...mapGetters('assessmentPlans/plan', ['rows']),
    ...mapState('assessmentPlans/plan', ['assessmentPlan', 'isLoading']),
  },
  components: { Alert, testPlanRows, testPlanRow, planDefault, statisticsCircle},
  watch: {
    assessmentPlan() {
      const {toast} = useToast()
      if (!this.assessmentPlan.uuid && !this.assessmentPlan.isCompleted) {
        toast.error('План оценок не найден')
        this.$router.push({name: 'AssessmentPlansList'})
      } else {
        if (this.assessmentPlan.isCompleted) {
          toast.error('План оценок остановлен')
          this.$router.push({name: 'AssessmentPlansList'})
        }
      }
    }
  }
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>