<template>
  <div>
    <div v-if="isLoading">
      <app-placeholder width="100%" margin-bottom="24px" height="40px" />
      <app-placeholder width="100%" margin-bottom="24px" height="120px" />
      <app-placeholder width="100%" margin-bottom="24px" height="120px" />
      <app-placeholder width="100%" margin-bottom="24px" height="40px" />
      <div class="d-flex">
        <app-placeholder
          width="calc(50% - 8px)"
          margin-right="16px"
          margin-bottom="24px"
          height="40px"
        />
        <app-placeholder
          width="calc(50% - 8px)"
          margin-bottom="24px"
          height="40px"
        />
      </div>
    </div>
    <div class="fields" v-else>
      <app-input
        v-model="planEditdataExd.name"
        label="Название"
        :errors="v$.planEditdataExd.name?.$errors"
      />
      <app-textarea v-model="planEditdataExd.description">
        <template #label>Описание</template>
      </app-textarea>

      <app-files-uploader
        label="Прикрепленные файлы"
        v-model="planEditdataExd.files"
      />

      <app-select
        v-model="order_type"
        item-value="slug"
        item-text="name"
        return-object
        label="Порядок прохождения"
        :items="orderTypeList"
        @change="selectOrderType"
      />
      <mt-time-interval-field
        :title="'Период доступа'"
        :dateFrom="planEditdataExd.date_from"
        :dateTo="planEditdataExd.date_to"
        withTime
        clearable
        @update-date="setDates"
      />
      <mt-switch
        hide-details
        label="Показывать прогресс / число баллов респонденту"
        v-model="planEditdataExd.show_progress"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import mtTimeIntervalField from '@/components/composite/mtTimeIntervalField.vue'
import mtSwitch from '@/components/UI/mtSwitch/index.vue'
import AppFilesUploader from '@/components/composite/AppFilesUploader.vue'
import ConfirmationDialog from '@/components/composite/confirmationDialog/confirmationDialog.vue'
import CustomTextarea from '@/components/UI/AppTextarea/AppTextarea.vue'
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import AppSelect from '@/components/UI/AppSelect/AppSelect.vue'
import AppTextarea from '@/components/UI/AppTextarea/AppTextarea.vue'
import useVuelidate from '@vuelidate/core'
import AppPreloader from '@/components/workspace/AppPreloader.vue'
import AppPlaceholder from '@/components/UI/AppPlaceholder/AppPlaceholder.vue'
import { useValidations } from '@/plugins/vuelidate-wrapper'

export default {
  components: {
    AppPlaceholder,
    AppPreloader,
    AppTextarea,
    AppSelect,
    AppInput,
    CustomTextarea,
    ConfirmationDialog,
    mtTimeIntervalField,
    mtSwitch,
    AppFilesUploader,
  },
  data: () => ({
    file: '',
    orderType: '',
    orderTypeList: [
      {
        slug: 'arbitrary',
        name: 'Произвольный',
      },
      {
        slug: 'seriatim',
        name: 'По порядку',
      },
    ],
    isConfirmationDialogOpen: false
  }),
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    const { required } = useValidations()
    
    return {
      planEditdataExd: {
        name: {
          required,
        },
      },
    }
  },
  computed: {
    ...mapGetters('assessmentPlans/plan', [
      /* ITEM */
      'planEditdataExd',
      'addEdit1Validation',
      'date_from',
      'date_to',
    ]),
    ...mapState('assessmentPlans/plan', ['isLoading']),

    order_type: {
      get() {
        return this.planEditdataExd.order_type
          ? this.orderTypeList.filter(
              (item) => item.slug === this.planEditdataExd.order_type,
            )[0]
          : this.orderTypeList[0]
      },
      set(newValue) {
        this.orderType = newValue
      },
    },
  },
  methods: {
    ...mapActions('assessmentPlans/plan', {
      setPlanStore: 'setPlanStore',
    }),


    /* SETTERS */
    setDates(payload = null) {
      if (!payload) return
      this.setPlanStore({
        date_from: payload.from,
        date_to: payload.to,
      })
    },

    selectOrderType(payload = {}) {
      this.setPlanStore({ order_type: this.orderType.slug })
    },

    async validate() {
      return await this.v$.$validate()
    }
  }
}
</script>

<style lang="scss"></style>
