import { TASK_TYPE_CONFIRMATION, TASK_TYPE_EXPERT, TASK_TYPE_TEST, TASK_TYPE_TEST_PRO } from '@/constants/testTypes';
import { makeCommonItem } from '@/adapters/assessmentPlans/commonItem.adapter';
import { makeFileItem, makeFileItems } from '@/adapters/files/files.adapter';
import { roundNumber } from '@/services/Mathe.js';


export const taskSingleAdapter = (item) => {

  return makeTaskItem(item);
}

export const taskIndexAdapter = (items) => {
  return items.map(item => makeTaskItem(item))
}
export const makeTaskItem = (item) => {
  let modifiedItem = makeCommonItem(item);
  return {
    ...modifiedItem,
    isCalculating: item.is_calculating,
    isCompleted: item.completed && (item.number_attempt === item.attempts),
    balls: {
      current: roundNumber(item.balls?.current),
      total: roundNumber(item.balls?.total),
    },
    type: makeTypeOfTask(item.type),
    hasNotifications: item.notifications,
    attempts: item.attempts,
    canRepeatAfterFail: item.failed && item.number_attempt > item.attempts,
    attemptsAmount: item.number_attempt,
    materials: makeFileItems(item.materials ?? item.files),
    isAvailableForExport: item.export?.availableExport,
    reports: item.export?.report,
    sessionUuid: item.export?.sessionUuid,
    userUuid: item.uuid_user,
    btn: {
      ...makeTaskBtn(
        item
      ),
      loading: false
    }
  }
}
const makeTypeOfTask = (type) => {
  let label = '';
  if(type === TASK_TYPE_TEST) label = 'Тест';
  if(type === TASK_TYPE_TEST_PRO) label = 'Тест';
  if(type === TASK_TYPE_EXPERT) label = 'Экс. оценка';

  return {
    label,
    value: type
  }
};
const makeTaskBtn = (item) => {
  const hasAttempts = item.number_attempt > item.attempts;

  if(item.is_calculating) {
    return {
      className: 'disabled',
      label: 'результат вычисляется...'
    }
  }
  if(item.failed) {
    if(!hasAttempts || item.isExpired) {
      return {
        className: 'failed-disabled',
        label: 'Неуспешно',
        failed: true
      }
    }
    if(item.attempts > 0 && hasAttempts && !item.isExpired) {
      return {
        className: 'finished',
        label: 'Повторить'
      }
    }
  }

  if(item.completed && (item.number_attempt === item.attempts)) {
    return {
      className: 'finished',
      label: 'Пройдено'
    }
  }

  if (!item.open) {
    return {
      className: 'disabled',
      icon: 'lock',
      label: item.isExpired ? 'недоступно' : 'пока недоступно'
    }
  }

  if(item.open) {
    let label = '';

    if (item.continue) {
      label = 'Продолжить выполнение';
    } else {
      label = 'Начать выполнение';
    }

    return {
      className: 'opened',
      label: label
    }
  }

  return new Error('No Status Found');

}